export enum ButtonType {
    Icon
}


const LoadingButton = ({
    title,
    loadingTitle,
    isLoading,
    isDisabled,
    color,
    className,
    iconClass,
    buttonType,
    onClick,
}: {
    title: string;
    loadingTitle: string;
    isLoading: boolean;
    isDisabled?: boolean;
    color?: string;
    className?: string;
    iconClass?: string;
    buttonType?: ButtonType;
    onClick?: () => Promise<void>;
}) => {
    return (
        <>
        {buttonType ===  ButtonType.Icon
        ? 
            <button 
                type="button" 
                className="btn btn-primary btn-circle has-tooltip" 
                aria-label={title}
                onClick={async () => {
                    if (onClick) {
                        await onClick();
                    }
                }}
                disabled={isLoading || isDisabled}
                >
                    <i className={iconClass}></i>
                    {isLoading && (
                        <span className="loader"></span>
                    )}
            </button>
        : 
            <button
            className={"btn btn-" + (color ?? "primary") + " " + (isLoading ? className : '') + " mt-auto d-inline-flex btn-fix-size justify-content-center btn-rounded" }
            onClick={async () => {
                if (onClick) {
                    await onClick();
                }
            }}
            disabled={isLoading || isDisabled}
        >
            {isLoading && (
                /*<svg>
                    <rect x="0" y="0" fill="none" width="100%" height="100%"/>
                </svg>*/

                <span
                    className="spinner-border me-2 flex-shrink-0"
                    style={{ height: "1.5em", width: "1.5em" }}
                    role="status"
                    aria-hidden="true"
                ></span>
            )}
            {isLoading? loadingTitle : title}
        </button>
        }
        </>
    );
};

export default LoadingButton;
