import { getAuth } from "firebase/auth";

export default function LogoutButton() {
	return (
		<span
			onClick={() => {
				getAuth().signOut();
			}}
			style={{ cursor: "pointer" }}
		>
			<img
				src="https://img.icons8.com/ios-glyphs/30/000000/shutdown.png"
				alt="logout"
			/>
		</span>
	);
}
