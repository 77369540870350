import useCustomerPortal from "./useCustomerPortal";

export default function ReactivateSubscriptionButton(
	props: IReactivateSubscriptionButtonProps
) {
	const {
		redirectToSubscriptionReactivationPage,
		isGettingToken,
		isRedirecting,
	} = useCustomerPortal();

	const loadingTitle = isGettingToken
		? "Getting Stripe access token..."
		: "Redirecting to Stripe...";
	const isLoading = isGettingToken || isRedirecting;

	return (
		<button
			className={`${props.className} ${isLoading ? "disabled" : ""}`}
			type="button"
			onClick={() =>
				redirectToSubscriptionReactivationPage(props.subscriptionId)
			}
		>
			{isLoading ? loadingTitle : "Renew subscription"}
		</button>
	);
}

interface IReactivateSubscriptionButtonProps {
	subscriptionId: string;
	className?: string;
}
