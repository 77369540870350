import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/js/bootstrap.min.js";
import "./index.css";
import "./custom.scss";
import App from "./App";
import DevIndicator from "./DevIndicator";
import ReactGA from "react-ga";

let gaPropertyId = process.env.REACT_APP_GA_PROPERTY_ID;
if (gaPropertyId) {
    ReactGA.initialize(gaPropertyId, {
        gaOptions: { cookieDomain: "auto", siteSpeedSampleRate: 100 },
    });
}

ReactDOM.render(
    <React.StrictMode>
        <DevIndicator />
        <App />
    </React.StrictMode>,
    document.getElementById("root"),
);
