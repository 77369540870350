import { useEffect, useState } from "react";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import firebaseApp from "./Firebase/firebase";

export default function useMaintenanceCheck() {
    const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);

    useEffect(() => {
        const firestore = getFirestore(firebaseApp);

        const docRef = doc(firestore, "configuration", "active");

        const unsubscribe = onSnapshot(docRef, (snapshot) => {
            let underMaintenance = snapshot.data()?.underMaintenance;
            if (underMaintenance !== undefined && underMaintenance !== null) {
                setIsUnderMaintenance(underMaintenance);
            }
        });

        return () => unsubscribe();
    });

    return { isUnderMaintenance };
}
