import { useState } from "react";
import { guacamoleTrialsUrl } from "../Guacamole";
import { url as apiUrl } from "../api";
import { setCookie } from "../cookie";
import sendPageview from "../GoogleAnalytics/ga";

export default function useTrial() {
    const [isLooking, setIsLooking] = useState(false);
    const [errorStatus, setErrorStatus] = useState<IErrorModel | null>(null);
    const [isRedirecting, setIsRedirecting] = useState(false);

    async function assignTrial(bufferKey: string, urlKey: string) {
        setIsLooking(true);
        setErrorStatus(null);
        sendPageview("trial-start", () =>
            apiAssignTrial(
                bufferKey,
                (response) => {
                    setIsRedirecting(true);
                    setIsLooking(false);
                    setCookie("X-Trial-Authentication", response.id, response.expiration);
                    setCookie("X-Trial-App", urlKey, response.expiration);
                    window.location.href = guacamoleTrialsUrl;
                },
                (errorCode, yourIp, assignments) => {
                    setErrorStatus({
                        status: errorCode,
                        yourIp: yourIp,
                        assignments: assignments,
                    });
                    setIsLooking(false);
                },
            ),
        );
    }

    return { assignTrial, isLooking, isRedirecting, errorStatus };
}

function apiAssignTrial(
    bufferKey: string,
    success: (response: IAssignResponse) => void,
    error: (status: number, yourIp: string | null, assignments: null | Array<Date>) => void,
) {
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            trialBufferKey: bufferKey,
        }),
    };

    fetch(apiUrl + "/trial-assign", options).then((response) => {
        if (response.status === 200 || response.status === 429) {
            response.json().then((json) => {
                if (response.status === 200) {
                    success({
                        id: json.id,
                        expiration: new Date(json.expiration),
                    });
                } else {
                    if (error) {
                        let assignments = null;
                        if (json.assignments) {
                            assignments = json.assignments.map((a: string) => new Date(a));
                        }

                        error(response.status, json.yourIp, assignments);
                    }
                }
            });
        } else {
            if (error) {
                error(response.status, null, null);
            }
        }
    });
}

interface IAssignResponse {
    id: string;
    expiration: Date;
}

interface IErrorModel {
    status: ErrorStatus;
    yourIp: string | null;
    assignments: Array<Date> | null;
}

export enum ErrorStatus {
    RepeatedLimits = 429,
    OutOfTrialVms = 404,
    AlreadyTrialing = 403,
}
