import LoadingButton from "../Components/LoadingButton";

const FactoryResetPanel = (props: FactoryResetProps) => {
    return (
        <>
            <div className="row-settings">
                <h5 className="fw-semibold">Factory reset</h5>
            </div>
            
            <div className="alert alert-danger fs-5">
                <p><b>Heads up!</b><br></br>Factory Reset will delete your VPS, including all data and settings.</p>
                <p>Your subscription plan will remain unchanged. <br></br>A new VPS with default settings will be assigned.​</p>
            </div>
            <div className="row-settings">
                <div className="row">
                    <div className="col-md-6 fs-5">Are you sure you want to perform a Factory Reset?</div>
                    <div className="col-md-4">
                        <div className="text-end">
                        <LoadingButton
                            title="Yes, Perform Factory Reset"
                            loadingTitle="Resetting..."
                            isLoading={false}
                            isDisabled={false}
                            className="mx-0"
                            onClick={props.resetToFactory}
                        />
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row mt-5">
                <div className="col-md-4 offset-md-4">
                    <button
                        type="button"
                        className="btn btn-outline-primary w-100 btn-rounded"
                        onClick={() => props.setIsFactoryResetVisible(false)}
                    >
                        Close
                    </button>
                </div>
            </div>
        </>
    );
};

export type FactoryResetProps = {
    subscriptionId: string;
    setIsFactoryResetVisible: Function;
    resetToFactory: () => Promise<void>;
};

export default FactoryResetPanel;