const preferences = { get: getPreferences, set: setPreferences };

export function getPreferences(): ISubscribePreferences {
    return {
        productId: window.sessionStorage.getItem("lastSubscribe-productId") ?? "",
        priceId: window.sessionStorage.getItem("lastSubscribe-priceId") ?? "",
        currency: window.sessionStorage.getItem("lastSubscribe-currency") ?? "",
        subscriptionType: window.sessionStorage.getItem("lastSubscribe-subscriptionType") ?? ""
    };
}

export function setPreferences(preferences: ISubscribePreferences) {
    window.sessionStorage.setItem("lastSubscribe-productId", preferences.productId);
    window.sessionStorage.setItem("lastSubscribe-priceId", preferences.priceId);
    window.sessionStorage.setItem("lastSubscribe-currency", preferences.currency);
    window.sessionStorage.setItem("lastSubscribe-subscriptionType", preferences.subscriptionType);
}

interface ISubscribePreferences {
    productId: string,
    priceId: string,
    currency: string,
    subscriptionType: string
}

export default preferences;