import { useState, useContext, useEffect, useCallback } from "react";
import { doc, getFirestore, setDoc, deleteDoc, onSnapshot, updateDoc } from "firebase/firestore";
import firebaseApp from "../Firebase/firebase";
import { UserContext } from "../Authentication/UserContext";
import { getIpAddress, getVirtualMachineUserPassword } from "../api";

const RemoteDesktopConnection = ({ subscriptionId, vmId }: RemoteDesktopConnectionProps) => {
    const { user } = useContext(UserContext);
    const [rdp, setRdp] = useState<RdpModel>({enabled: false});
    const [showRdpPwd, setShowRdpPwd] = useState(false);
    const [rdpPwd, setRdpPwd] = useState<string | undefined>(undefined);
    const [ipIsValid, setIpIsValid] = useState(true)
    const [isCopied, setIsCopied] = useState(false);

    const firestore = getFirestore(firebaseApp);

    const getRdpDocRef = useCallback((userid:string, subscriptionId:string) => {
        const docPath = `customers/${userid}/rdp-connections/${subscriptionId}`;
        return doc(firestore, docPath);
    }, [firestore]);

    useEffect(() => {
        if (!user) return; 
        const docRef = getRdpDocRef(user.id, subscriptionId);
        const unsubscribe = onSnapshot(docRef, (snapshot) => {
            if (snapshot.exists()) {
                const docData = snapshot.data();
                setRdp({enabled: true, allowedIpAddress: docData.allowedIpAddress, port: docData.port, lastError: docData.lastError})
            } else {
                setRdp({enabled: false})
            }
        });

        return () => unsubscribe();
    },[user, subscriptionId, getRdpDocRef])

    useEffect(() => {
        async function getPassword() {
            if (!user) return;
            const password = await getVirtualMachineUserPassword(user?.token, vmId);
            setRdpPwd(password);
        }
        getPassword();
    },[user, vmId])

    const toggleRdp = async (enable: boolean) => {
        if (!user) return;
        
        const rdpDocRef = getRdpDocRef(user.id, subscriptionId);
        if (enable) {
            const ip = await getIpAddress();
            await setDoc(rdpDocRef, {
                vmId,
                userId: user.id,
                subscriptionId,
                allowedIpAddress: ip
            });
        } else {
            await deleteDoc(rdpDocRef)
        }
    };

    const copyRdpPwd = () => {
        if (rdpPwd) {
            navigator.clipboard.writeText(rdpPwd);
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        }   
    };

    const handleRdpIpBlur = async (e: any) => {
        if (!user) return;

        const ipAddress = e.target.value;
        const regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        const isValid = regex.test(ipAddress);

        if (!isValid) {
            setIpIsValid(false);
            return;
        } 
        
        const docRef = getRdpDocRef(user.id, subscriptionId);

        await setDoc(docRef, {
            allowedIpAddress: ipAddress
        }, {merge: true});

        setIpIsValid(true);
      }


    return (
        <div id="rdpBlock">
            {rdp.lastError && (
                    <p className="alert alert-danger mt-4 fs-5">Enabling RDP connection failed.</p>
                )}
            <div className="row align-items-center py-3">
                <label className="col-md-6 fs-5" htmlFor="rdpSwitch">
                    Remote Desktop Connection
                </label>
                <div className="col-md-4">
                    <div className="row g-3 align-items-center">
                        <div className="col-auto form-switch form-switch-lg ps-3">
                            <input
                                className="form-check-input ms-0"
                                type="checkbox"
                                role="switch"
                                id="rdpSwitch"
                                checked={rdp.enabled}
                                onChange={() => toggleRdp(!rdp.enabled)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={`row align-items-center py-3 ${!rdp.enabled ? "opacity-50" : ""}`}>
                <label className="col-md-6 fs-5" htmlFor="rdpIp">
                    RDP access allowed from IP address
                </label>
                <div className="col-md-6">
                    <div className="row g-3 align-items-center">
                        <div className="col-auto ps-3">
                            {rdp.enabled && (
                                <input
                                    className="form-control ms-0"
                                    style={{ width: "250px", border: ( ipIsValid ? "1px solid #1db0ea" : "2px solid #eb1018") }}
                                    type="text"
                                    id="rdpIp"
                                    defaultValue={rdp.allowedIpAddress}
                                    onBlur={handleRdpIpBlur}
                                />
                            )}
                            {!rdp.enabled && (
                                <div style={{ height: "38px", display: "flex", alignItems: "center" }}>&ndash;</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`row align-items-top py-3 ${!rdp.enabled ? "opacity-50" : ""}`}>
                <label className="col-md-6 fs-5">Your RDP login details</label>
                <div
                    className="col-md-6 fs-5 d-grid"
                    style={{ gridAutoRows: "minmax(38px, 1fr)", position: "relative" }}
                >
                    <div className="row gx-3 align-items-center">
                        <label className="col-md-4 ps-3">Computer:</label>

                        <div className="col-auto">{rdp.enabled ? "rdp.apponfly.com:" + (rdp.port ? rdp.port : "-") : "-"}</div>
                    </div>

                    <div className="row gx-3 align-items-center">
                        <label className="col-md-4 ps-3">Username:</label>

                        <div className="col-auto">{rdp.enabled ? "Administrator" : "-"}</div>
                    </div>

                    <div className="row gx-3 align-items-center">
                        <label className="col-md-4 ps-3">Password:</label>

                        {rdp.enabled && (
                            <div className="col-md-8">
                                {rdpPwd ? 
                                <div className="d-flex">
                                    <input
                                        className="form-control ms-0 form-pwd"
                                        type={showRdpPwd ? "text" : "password"}
                                        id="rdpPwd"
                                        value={rdpPwd}
                                        disabled
                                        />
                                    <button
                                        className="btn btn-link ms-2 p-0"
                                        onClick={() => setShowRdpPwd(!showRdpPwd)}
                                    >
                                        <i className={"bi fs-5 bi-eye" + (showRdpPwd ? "-slash" : "")}></i>
                                    </button>
                                    <button className="btn btn-link ms-2 p-0" onClick={() => copyRdpPwd()}>
                                        <i className="bi bi-clipboard fs-5"></i>
                                    </button>
                                </div> :
                                <span>-</span>}
                                {isCopied && <span style={{ position: "absolute", fontSize: "0.75rem" }}>Copied!</span>}
                            </div>
                        )}

                        {!rdp.enabled && <div className="col-auto">-</div>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RemoteDesktopConnection;

type RemoteDesktopConnectionProps = {
    subscriptionId: string;
    vmId: string;
};

type RdpModel = {
    enabled: boolean,
    allowedIpAddress?: string | undefined
    port?: string | undefined
    lastError?: Date | undefined
}
