import { useState } from "react";
import { UserContext, IUser } from "./Authentication/UserContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RequireAuthenticated from "./Authentication/RequireAuthenticated";
import UserPortal from "./Pages/UserPortal";
import Subscribe from "./Pages/Subscribe";
import Trial from "./Pages/Trial";
import packageJson from "../package.json";
import useMaintenanceCheck from "./useMaintenanceCheck";
import UnderMaintenance from "./UnderMaintenance";
import Connecting from "./Pages/Connecting";

function App() {
    const [user, setUser] = useState<IUser | null>(null);
    const userContextValue = { user, setUser };
    const queryParams = new URLSearchParams(window.location.search);
    const preHeading = queryParams.has("expired")
        ? {
              __html: "Thank you for trialling AppOnFly. <br />Your free trial has expired",
          }
        : null;
    const { isUnderMaintenance } = useMaintenanceCheck();

    return (
        <>
            {isUnderMaintenance ? (
                <UnderMaintenance />
            ) : (
                <>
                    <UserContext.Provider value={userContextValue}>
                        <BrowserRouter>
                            <Routes>
                                <Route
                                    path="/"
                                    element={
                                        <RequireAuthenticated loggedInUrl={getLoggedInUrl()}>
                                            <UserPortal />
                                        </RequireAuthenticated>
                                    }
                                />
                                <Route
                                    path="/subscribe"
                                    element={
                                        <RequireAuthenticated
                                            heading="Subscribe to AppOnFly"
                                            preHeading={preHeading}
                                            postHeading={{
                                                __html: 'By signing up, I agree to the AppOnFly <a target="_blank" href="https://www.apponfly.com/terms-of-service" class="text-dark">Privacy Policy</a> and <a target="_blank" href="https://www.apponfly.com/terms-of-service" class="text-dark">Terms of Service</a>.',
                                            }}
                                        >
                                            <Subscribe />
                                        </RequireAuthenticated>
                                    }
                                />
                                <Route path="/connecting" element={<Connecting />} />
                                <Route path="/trial" element={<Trial />} />
                            </Routes>
                        </BrowserRouter>
                    </UserContext.Provider>

                    <div className="fixed-bottom text-end text-muted p-1 fs-6">v{packageJson.version}</div>
                </>
            )}
        </>
    );
}

function getLoggedInUrl(): string {
    let query = "";
    if (window.location.pathname === "/") {
        query = window.location.search;
    }

    return `${window.location.origin}${window.location.pathname}${query}`;
}

export default App;
