import { useContext, useEffect, useState } from "react";
import {
    resetVmPasswordForSubscription,
    selectSubscription,
    resetVmToFactoryForSubscription,
    startVmForSubscription,
    stopVmForSubscription,
} from "../api";
import { UserContext } from "../Authentication/UserContext";
import { setCookie } from "../cookie";
import { VM_STATUS } from "./vmStatus";
import { guacamoleSubsUrl } from "../Guacamole";
import { doc, updateDoc, getFirestore } from "firebase/firestore";
import firebaseApp from "../Firebase/firebase";

export default function useSubscriptionVm(subId: string, vmStatus: string, isAutoShutdownEnabled: boolean) {
    const { user } = useContext(UserContext);
    const [isConnectionRequested, setIsConnectionRequested] = useState(false);
    const [isPasswordResetRequested, setIsPasswordResetRequested] = useState(false);
    const [isConnecting, setIsConnecting] = useState(false);
    const [isResettingPassword, setIsResetingPassword] = useState(false);
    const [isResettingToFactory, setIsResetingToFactory] = useState(false);
    const [isTurningVmOn, setIsTurningVmOn] = useState(false);
    const [isTurningVmOff, setIsTurningVmOff] = useState(false);
    const [isVmInTransition, setIsVmInTransition] = useState(false);
    const firestore = getFirestore(firebaseApp);

    const [quacamoleWindow, setQuacamoleWindow] = useState(null as Window | null);

    useEffect(() => {
        if (!vmStatus) {
            setIsVmInTransition(true);
        }
        if (vmStatus === VM_STATUS.CHANGING_PASSWORD) {
            setIsResetingPassword(true);
            setIsVmInTransition(true);
        }
        if (vmStatus === VM_STATUS.RESETTING_TO_FACTORY) {
            setIsResetingToFactory(true);
            setIsVmInTransition(true);
        }

        if (vmStatus === VM_STATUS.STARTING) {
            setIsTurningVmOn(true);
            setIsVmInTransition(true);
        }
        if (vmStatus === VM_STATUS.STOPPING) {
            setIsTurningVmOff(true);
            setIsVmInTransition(true);
        }
    }, [vmStatus]);

    useEffect(() => {
        if ([VM_STATUS.RUNNING, VM_STATUS.STOPPED].includes(vmStatus)) {
            setIsConnecting(false);
            setIsResetingPassword(false);
            setIsResetingToFactory(false);
            setIsTurningVmOn(false);
            setIsTurningVmOff(false);
            setIsVmInTransition(false);
        }
    }, [vmStatus]);

    useEffect(() => {
        if (vmStatus === VM_STATUS.RUNNING && isPasswordResetRequested) {
            resetPassword(subId);
            setIsPasswordResetRequested(false);
        }

        // if (vmStatus === VM_STATUS.RUNNING && isConnectionRequested) {
        //     setIsConnectionRequested(false);
        //     connectToVm(subId);
        // }
    }, [vmStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (vmStatus === VM_STATUS.RUNNING && isConnectionRequested && quacamoleWindow !== null) {
            try {
                quacamoleWindow.location.assign(guacamoleSubsUrl);
                quacamoleWindow.focus();
            } catch (e) {
                console.log(e);
                alert("Pop-up Blocker is enabled! Please disable your pop-up blocker.");
            }

            setIsConnecting(false);
            setIsVmInTransition(false);
            setIsConnectionRequested(false);
            setQuacamoleWindow(null);
        }
    }, [vmStatus, isConnectionRequested, quacamoleWindow])

    async function connectToVm(subId: string) {
        if (!user) return;
        setIsConnecting(true);
        setIsVmInTransition(true);
        setQuacamoleWindow(window.open('/connecting', '_blank'));

        await selectSubscription(user.token, subId, (response) => {
            setCookie("X-Subscription", response.key, new Date(response.expiration));
            setIsConnectionRequested(true);
        });
    }

    async function resetPassword(subId: string) {
        if (!user) return;
        setIsResetingPassword(true);
        setIsVmInTransition(true);
        await resetVmPasswordForSubscription(user.token, subId, () => setIsPasswordResetRequested(true));
    }

    async function resetToFactory(subId: string) {
        if (!user) return;
        setIsVmInTransition(true);
        setIsResetingToFactory(true);
        await resetVmToFactoryForSubscription(user.token, subId);
    }

    async function turnSubscriptionVmOn(subId: string) {
        if (!user) return;
        setIsTurningVmOn(true);
        setIsVmInTransition(true);
        await startVmForSubscription(user.token, subId);
    }

    async function turnSubscriptionVmOff(subId: string) {
        if (!user) return;
        setIsTurningVmOff(true);
        setIsVmInTransition(true);
        await stopVmForSubscription(user.token, subId);
    }

    async function toggleAutoShutdown(subId: string, enable: boolean) {
        if (!user) return;
        setIsVmInTransition(true);
        const docPath = `customers/${user.id}/shutdowns/${subId}`;
        const docRef = doc(firestore, docPath);
        await updateDoc(docRef, {
            id: subId,
        });
        if (!enable) {
            await updateDoc(docRef, {
                autoShutdownTime: null
            });
        }
        setIsVmInTransition(false);
    }

    return {
        connectToVm,
        isConnecting,
        resetPassword,
        isResettingPassword,
        turnSubscriptionVmOff,
        isTurningVmOff,
        turnSubscriptionVmOn,
        isTurningVmOn,
        toggleAutoShutdown,
        isVmInTransition,
        resetToFactory,
        isResettingToFactory
    };
}
