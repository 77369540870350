import { UserName } from "../Authentication/UserContext";
import LogoutButton from "../Authentication/LogoutButton";
import CustomerPortalButton from "../Stripe/CustomerPortalButton";
import { NavLink } from "react-router-dom";

const LoggedInUserPanel = (props: ILoggedInUserPanelProps) => {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light px-3">
                <NavLink to={"/"}>
                    <img src="/logo-large.png" style={{ height: 54 }} alt="logo" />
                </NavLink>
                {!props.isOnlyCancelVisible && (
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                )}
                {props.isOnlyCancelVisible ? (
                    <div className="ms-auto">
                        <NavLink to={"/"} style={{textDecoration: "none", color: "#000"}}>
                            <span style={{verticalAlign: "middle"}}>Cancel &nbsp;</span>
                        <svg viewBox="55.951 55.951 88.098 88.098" height="15" width="15" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                          <path d="M142.828 137.172a4 4 0 1 1-5.656 5.656L100 105.656l-37.172 37.172c-.78.781-1.805 1.172-2.828 1.172s-2.048-.391-2.828-1.172a4 4 0 0 1 0-5.656L94.344 100 57.172 62.828a4 4 0 1 1 5.656-5.656L100 94.344l37.172-37.172a4 4 0 1 1 5.656 5.656L105.656 100l37.172 37.172z" fill="#000001" data-color="1"></path>
                        </svg>
                    </NavLink>
                    </div>
                ) : (
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item me-2">
                                <NavLink className="nav-link" to={"/"}>
                                    My VPS & Apps
                                </NavLink>
                            </li>
                            <li className="nav-item me-2">
                                <CustomerPortalButton className="nav-link" />
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://help.apponfly.com/"
                                >
                                    Support
                                </a>
                            </li>
                        </ul>
                        <ul className="navbar-nav">
                            <span className="nav-item navbar-text me-4">
                                <UserName />
                            </span>
                            <span className="nav-item navbar-text">
                                <LogoutButton />
                            </span>
                        </ul>
                    </div>
                )}
            </nav>
        </>
    );
};

interface ILoggedInUserPanelProps {
    title?: string;
    isOnlyCancelVisible?: boolean;
}

export default LoggedInUserPanel;
