import { useEffect } from "react";
import Subscriptions from "../Subscriptions/Subscriptions";
import LoggedInUserPanel from "../User/LoggedInUserPanel";
import sendPageview from "../GoogleAnalytics/ga";

const UserPortal = () => {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has("stripe-payment-successful")) sendPageview("payment-successful");

        window.history.replaceState({}, "", `${window.location.origin}${window.location.pathname}`);
    }, []);

    return (
        <>
            <div className="container mt-2">
                <LoggedInUserPanel title="Virtual machines" />
            </div>
            <div className="container mt-5">
                <div className="container-user-portal">
                    <Subscriptions />
                </div>
            </div>
        </>
    );
};

export default UserPortal;
