import differenceInSeconds from "date-fns/differenceInSeconds";
import PaymentLinkButton from "../Stripe/PaymentLinkButton";
import ReactivateSubscriptionButton from "../Stripe/ReactivateSubscriptionButton";
import { PasswordResetResultModel } from "./Subscription";
import { SUBSCRIPTION_TYPE } from "./subscriptionType";
import { SUB_STATUS } from "./subStatus";

const SubscriptionStatusPanel = (props: VmStatusPanelProps) => {
    function getDeletionDate(date: Date) {
        let newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 7); // 1 týden se zkouší opakované platby (v případě neúspěšné platby při prodloužení)
        return newDate;
    }

    function getFormattedDate(date: Date | undefined) {
        if (!date) return "";
        return new Date(date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    }

    function showStatus(isSettingsVisible?: boolean) {
        return (
            !isSettingsVisible && !props.performingFactoryReset && (
                <p className="mt-4 fs-5">{props.vmStatus}</p>
            ));
    }

    return (
        <>
            {props.vmStatus && (
                <>
                    {showStatus(props.isPanelsSetingsVisible)}
                </>
            )}
            {[SUB_STATUS.PAST_DUE, SUB_STATUS.INCOMPLETE].includes(props.status) && (
                <>
                    <p className="alert alert-danger mt-4 fs-5">
                        Last payment failed. Please renew your subscription.
                        <br />
                        If you do not renew your subscription by {getFormattedDate(getDeletionDate(props.start))}, your
                        VPS including all data and settings will be deleted.
                    </p>
                    <div className="row gx-1 mt-3 mb-4">
                        <div className="col-md-4">
                            <PaymentLinkButton
                                subscriptionId={props.subscriptionId}
                                className="btn btn-primary w-100 btn-rounded"
                            />
                        </div>
                    </div>
                </>
            )}
            {props.cancelAt && (
                <>
                    <p className="alert alert-danger mt-4 fs-5">
                        Your subscription has been scheduled for cancellation.
                        <br />{" "}
                        {props.subscriptionType === SUBSCRIPTION_TYPE.FLAT
                            ? `If you do not renew your subscription by ${getFormattedDate(
                                  props.cancelAt,
                              )}, your VPS including all data and settings will be deleted.`
                            : `If you do not renew your subscription before consuming the remaining hours, at latest by ${getFormattedDate(
                                  props.cancelAt,
                              )}, your VPS including all data and settings will be deleted.`}
                    </p>
                    <div className="row gx-1 mt-3 mb-4">
                        <div className="col-md-4">
                            <ReactivateSubscriptionButton
                                subscriptionId={props.subscriptionId}
                                className="btn btn-primary w-100 btn-rounded"
                            />
                        </div>
                    </div>
                </>
            )}
            {[SUB_STATUS.ACTIVE, SUB_STATUS.PAST_DUE].includes(props.status) && !props.vmStatus && (
                <>
                <p className="mt-4 fs-5">Creating</p>
                <p className="alert alert-success mt-4 fs-5">
                    We are spinning up your VPS. It will be up and running in a few minutes.
                </p>
                </>
            )}
            {props.passwordResetResult?.isSuccess === true &&
                differenceInSeconds(new Date(Date.now()), props.passwordResetResult?.timestamp) < 10 && (
                    <p className="alert alert-success mt-4 fs-5">Your VPS password has been successfully reset.</p>
                )}
            {props.passwordResetResult?.isSuccess === false &&
                differenceInSeconds(new Date(Date.now()), props.passwordResetResult?.timestamp) < 10 && (
                    <p className="alert alert-danger mt-4 fs-5">
                        Password reset failed. Looks like you have disabled AppOnFly Support account or changed your
                        Windows IP address. Contact AppOnFly Support.
                    </p>
                )}
            {props.performingFactoryReset && (
                <>
                <p className="mt-4 fs-5">Performing Factory Reset</p>
                <p className="alert alert-success mt-4 fs-5">
                    We are spinning up your VPS. It will be up and running in a few minutes.
                </p>
                </>
            )}
        </>
    );
};

export type VmStatusPanelProps = {
    subscriptionId: string;
    status: string;
    vmStatus: string;
    cancelAt?: Date;
    start: Date;
    passwordResetResult?: PasswordResetResultModel;
    subscriptionType: string;
    isPanelsSetingsVisible?: boolean;
    performingFactoryReset?: boolean;
};

export default SubscriptionStatusPanel;
