const Connecting = () => {
    return (
        <>
            <div className="container mt-4 text-center">
                <h2>Connecting...</h2>
                <h4>Please wait while we prepare VM for you.</h4>
            </div>
        </>
    );
};

export default Connecting;
