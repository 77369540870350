import React, { useState, useEffect, useContext } from "react";
import {
  doc,
  getDoc,
  getFirestore,
  Timestamp,
  setDoc,
} from "firebase/firestore";
import firebaseApp from "../Firebase/firebase";
import { UserContext } from "../Authentication/UserContext";

const SettingsShutdownTimer = ({ subscriptionId }: SettingsShutdownTimerProps) => {
  const [shutdownTime, setShutdownTime] = useState("-");
  const [autoshutdown, setAutoshutdown] = useState(false);
  const firestore = getFirestore(firebaseApp);
  const { user } = useContext(UserContext);

  const toggleAutoShutdown = async (subId: string, enable: boolean) => {
    const docPath = `customers/${user?.id}/shutdowns/${subId}`;
    const docRef = doc(firestore, docPath);

    if (!enable)
    {
        await setDoc(
            docRef,
            {
                autoShutdownTime: null,
                id: subId
            },
            { merge: true }
          );
    }
    else
    {
      setShutdownTime("3h 0m")
    }
    
    await setDoc(
        docRef,
        {
            defaultAutoShutdownEnabled: enable,
            defaultAutoShutdownTime: shutdownTime,
            id: subId
        },
        { merge: true }
      );
    setAutoshutdown(enable);
  };

  useEffect(() => {
    const fetchAutoShutdownTime = async () => {
      try {
        if (user) {
          const docPath = `customers/${user.id}/shutdowns/${subscriptionId}`;
          const docRef = doc(firestore, docPath);
          const customerDocSnapshot = await getDoc(docRef);

          if (customerDocSnapshot.exists()) {
            const { defaultAutoShutdownEnabled, defaultAutoShutdownTime } =
              customerDocSnapshot.data() || {};
            setAutoshutdown(defaultAutoShutdownEnabled ?? false);
            setShutdownTime(defaultAutoShutdownTime ?? "3h 0m");
            }
          }
        }
       catch (error) {
        console.error("Error fetching autoShutdownTime:", error);
      }
    };

    fetchAutoShutdownTime();
  }, [user, autoshutdown, subscriptionId, firestore]);

  useEffect(() => {
    const updateAutoShutdownTime = async (
        subscriptionId: any,
        newAutoShutdownTime: string
      ) => {
        try {
          if (user && autoshutdown) {
            const docPath = `customers/${user.id}/shutdowns/${subscriptionId}`;
            const docRef = doc(firestore, docPath);
            await setDoc(
              docRef,
              {
                defaultAutoShutdownTime: newAutoShutdownTime,
                defaultAutoShutdownEnabled: true,
                id: subscriptionId,
              },
              { merge: true }
            );
          }
        } catch (error) {
          console.error("Error updating autoShutdownTime:", error);
        }
      };
      updateAutoShutdownTime(subscriptionId, shutdownTime);    
  }, [user, autoshutdown, subscriptionId, firestore, shutdownTime]);

  const changeTime = (type: string) => {
    if (shutdownTime) {
      const regex = /(\d{1,2}[hm]\s{0,1}){1,2}$/g;
      const validDate = regex.test(shutdownTime);

      if (validDate && shutdownTime) {
        let minutes = toMinutes(shutdownTime);
        
        if (type === "plus") {
          //1440 = 24 hours, maximum
          if (minutes < 1440) {
            minutes = Math.floor(minutes / 30) * 30 + 30
          }
        } else {
          if (minutes > 30) {
            minutes = Math.ceil(minutes / 30) * 30 - 30
          }
        }

        if (minutes < 0) {
          // If minutes go negative, reset to 0h 0m
          setShutdownTime("-");
        } else {
          // Handle positive minutes by adjusting hours
          let hours = Math.floor(minutes / 60);
          minutes = minutes % 60;

          const times = { hours, minutes };
          setShutdownTime(`${times.hours}h ${times.minutes}m`);
        }
      } else {
        setShutdownTime("-");
      }
    }
  };

  const roundTime = (time: number, minutesToRound: number) => {
    const rounded = Math.round(time / minutesToRound) * minutesToRound;
    let roundedHours = Math.floor(rounded / 60);
    let roundedMinutes = rounded % 60;

    if (roundedHours < 0 && roundedMinutes < 0) {
      roundedHours = 0;
      roundedMinutes = 0;
    }

    if (roundedHours >= 24 && roundedMinutes === 30) {
      roundedHours = 24;
      roundedMinutes = 0;
    }

    return { hours: roundedHours, minutes: roundedMinutes };
  };

  const toMinutes = (ts: string) =>
    ts?.split(" ").reduce((acc: number, cur: string) => {
      const num = parseInt(cur);
      const [isHour, isMinute] = [cur.endsWith("h"), cur.endsWith("m")];
      return num * (isHour ? 60 : 1) + acc;
    }, 0);

  return (
    <div className="row g-3 align-items-center justify-content-between">
    {(
      <div className="col-auto form-switch form-switch-lg ps-3">
          <input
            className="form-check-input ms-0"
            type="checkbox"
            role="switch"
            id="autoShutdownCheck"
            checked={autoshutdown}
            onChange={() => toggleAutoShutdown(subscriptionId, !autoshutdown)}
          />
        </div>
      )}
      <div className="col-auto">
        <div className="input-group input-group-sm shutdown">
          <button
            className="btn btn-outline-primary btn-rounded-start"
            style={!autoshutdown ? { color: "#999", borderColor: "#999", pointerEvents: "none", cursor: "not-allowed" } : {}}
            onClick={() => changeTime("minus")}
            disabled={!autoshutdown}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-dash"
              viewBox="0 0 16 16"
            >
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
            </svg>
          </button>
          <input
            value={autoshutdown ? shutdownTime : '-'}
            type="text"
            id="ev_time"
            style={{ width: "5em" }}
            className="form-control text-center"
            name="time"
            placeholder={autoshutdown ? "0h" : '-'}
            autoComplete="off"
            readOnly={true}
            disabled={!autoshutdown}
          />
          <button
            className="btn btn-outline-primary btn-rounded-end"
            style={!autoshutdown ? { color: "#999", borderColor: "#999", pointerEvents: "none", cursor: "not-allowed" } : {}}
            onClick={() => changeTime("plus")}
            disabled={!autoshutdown}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-plus"
              viewBox="0 0 16 16"
            >
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SettingsShutdownTimer;

type SettingsShutdownTimerProps = {
  subscriptionId: string;
};
