import React, { useState } from "react";
import styled from "styled-components";
import LoadingButton from "../Components/LoadingButton";
import { IPrice } from "./Price";
import { SUBSCRIPTION_TYPE } from "../Subscriptions/subscriptionType";

interface IBannerProps {
  onPriceSelect: Function;
  isMostPopular: boolean;
  cheapestPlan?: IPrice;
}

const BannerWrapper = styled.div<{
  isMostPopular: boolean;
  isCheapest: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 0.25rem;
  padding: 20px;
  width: 220px;
  max-width: 100%;
  padding-top: 20px;
  /* height: ${({ isMostPopular }) => (isMostPopular ? "425px" : "400px")}; */
  height: 456px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  /* transform: ${({ isMostPopular }) =>
    isMostPopular ? "translateY(-15px)" : "none"}; */
  margin-inline: auto;
`;

const BannerHours = styled.span`
  margin-top: 35px;
  font-size: 20px;
  font-weight: 600;
`;

const BannerPerYear = styled.span`
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 44px;
`;

const BannerCurrentPrice = styled.span`
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const BannerOldPrice = styled.span`
  font-size: 20px;
  text-decoration: line-through;
  text-decoratin-color: #000;
  color: #666;
  margin-bottom: 16px;
`;

const BannerSavings = styled.span`
  font-size: 20px;
`;

const StyledLoadingButton = styled(LoadingButton)<{ isMostPopular: boolean }>`
  margin-top: auto
  }
`;

const PopularBox = styled.div`
  position: absolute;
  top: 0;
  left: -1px;
  transform: translateY(-90%);
  background-color: #3fbced;
  border: 1px solid black;
  border-radius: 0.25rem;
  padding: 10px;
  color: #fff;
  font-size: 14px;
  letter-spacing: 1px;
  width: 220px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
`;

const PriceBanner = (props: IPrice & IBannerProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const currencySymbol = props.currency === "usd" ? "$" : "€";
  const isCheapest = props.cheapestPlan?.id === props.id;

  const hours = props.hours ?? 0;
  const cheapestHours = props.cheapestPlan?.hours ?? 0;
  const cheapestPrice = props.cheapestPlan?.amount ?? 0;
  const price = props.amount;
  const oldPrice =
    props.subscriptionType === SUBSCRIPTION_TYPE.PAYG
      ? (cheapestPrice / cheapestHours) * hours
      : cheapestPrice * 12;
  const hoursFree =
    props.subscriptionType === SUBSCRIPTION_TYPE.PAYG
      ? (oldPrice - price) / (cheapestPrice / cheapestHours)
      : (oldPrice - price) / cheapestPrice;
  const save =
    props.subscriptionType === SUBSCRIPTION_TYPE.PAYG
      ? 1 - price / hours / (cheapestPrice / cheapestHours)
      : 1 - price / 12 / cheapestPrice;

  const hoursLabel =
    props.subscriptionType === SUBSCRIPTION_TYPE.PAYG
      ? `${hours} hours`
      : `1 ${props.interval.charAt(0).toUpperCase() + props.interval.slice(1)}`;
  const bannerLabel = `${currencySymbol}${props.amount}`;
  const oldPriceLabel = `${currencySymbol}${oldPrice.toFixed(2)}`;
  const savingsLabel = `${hoursFree.toFixed(0)} ${
    props.subscriptionType === SUBSCRIPTION_TYPE.PAYG ? "hours" : "months"
  }`;

  const handleSubscribe = async (): Promise<void> => {
    setIsLoading(true);
    props.onPriceSelect(props);
  };

  return (
    <BannerWrapper isMostPopular={props.isMostPopular} isCheapest={isCheapest}>
      {props.isMostPopular && <PopularBox>Most Popular</PopularBox>}
      <BannerHours>{hoursLabel}</BannerHours>
      {props.subscriptionType === SUBSCRIPTION_TYPE.PAYG && 
        <BannerPerYear>per 1 year</BannerPerYear>
      }
      <BannerPerYear></BannerPerYear>
      <BannerCurrentPrice>{bannerLabel}</BannerCurrentPrice>
      {!isCheapest && <BannerOldPrice>{oldPriceLabel}</BannerOldPrice>}
      {!isCheapest && <BannerSavings> {savingsLabel} free</BannerSavings>}
      {!isCheapest && (
        <BannerSavings> Save {Math.ceil((save * 100))}%</BannerSavings>
      )}
      <StyledLoadingButton
        title="Subscribe now"
        loadingTitle=""
        className="loading-button"
        isLoading={isLoading}
        onClick={handleSubscribe}
        isMostPopular={props.isMostPopular}
      />
    </BannerWrapper>
  );
};

export default PriceBanner;