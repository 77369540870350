import LoadingButton from "../Components/LoadingButton";
import { ButtonType } from "../Components/LoadingButton";
import ShutdownTimer from "../Components/ShutdownTimer";
import { VM_STATUS } from "./vmStatus";

const VmControlPanel = (props: VmControlPanelProps) => {
    return (
        <div className="row g-3 mt-2 align-items-center">
            <div className="col-auto">
            <ShutdownTimer 
                    subscriptionId={props.subscriptionId}
                    vmStatus={props.vmStatus}
            />
            </div>
            <div className="col-auto">
                <button
                    type="button"
                    className="btn btn-primary btn-circle has-tooltip"
                    onClick={() => props.setIsVmSettingsVisible(true)}
                    aria-label="Settings"
                >
                    <i className="bi bi-gear"></i>
                </button>
            </div>
            
            {![VM_STATUS.STOPPED, VM_STATUS.STARTING].includes(props.vmStatus) && (
                <div className="col-auto">
                    <LoadingButton
                        title="Turn off"
                        loadingTitle="Turning off..."
                        isLoading={props.isTurningVmOff}
                        isDisabled={props.isVmInTransition}
                        className="w-100 "
                        iconClass="bi bi-power icon-power"
                        buttonType={ButtonType.Icon}
                        onClick={() => props.turnSubscriptionVmOff(props.subscriptionId)}
                    ></LoadingButton>
                </div>
            )}
            {[VM_STATUS.STOPPED, VM_STATUS.STARTING].includes(props.vmStatus) && (
                <div className="col-auto">
                    <LoadingButton
                        title="Turn on"
                        loadingTitle="Turning on..."
                        isLoading={props.isTurningVmOn}
                        isDisabled={props.isVmInTransition}
                        className="w-100"
                        iconClass="bi bi-power icon-power"
                        buttonType={ButtonType.Icon}
                        onClick={() => props.turnSubscriptionVmOn(props.subscriptionId)}
                    ></LoadingButton>
                </div>
            )}
            <div className="col-md-auto ms-md-auto">
                <LoadingButton
                    title="Connect"
                    loadingTitle="Connect"
                    isLoading={props.isConnecting}
                    isDisabled={props.isVmInTransition}
                    className="btn-fix-size loader-square"
                    onClick={() => props.connectToVm(props.subscriptionId)}
                />
            </div>
            
        </div>
    );
};

export type VmControlPanelProps = {
    subscriptionId: string;
    vmStatus: string;
    isConnecting: boolean;
    isVmInTransition: boolean;
    isTurningVmOff: boolean;
    isTurningVmOn: boolean;
    connectToVm: Function;
    turnSubscriptionVmOff: Function;
    turnSubscriptionVmOn: Function;
    setIsVmSettingsVisible: Function;
};

export default VmControlPanel;
