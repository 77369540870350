import { createContext, useContext } from "react";

export interface IUser {
    id: string;
    name: string | null;
    email: string;
    token: string;
    currency: string | null;
    stripeLink: string;
}

export interface IUserContext {
    user: IUser | null;
    setUser: Function;
}

const UserContext = createContext<IUserContext>({
    user: null,
    setUser: () => {},
});

function UserName() {
    const email = useContext(UserContext).user?.email;
    return <>{email}</>;
}

export { UserContext, UserName };
