import { useContext, useState } from "react";
import firebaseApp from "../Firebase/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { api } from "../api";
import { UserContext } from "../Authentication/UserContext";

export default function useCustomerPortal() {
    const { user } = useContext(UserContext);
    const [isGettingToken, setIsGettingToken] = useState(false);
    const [isRedirecting, setIsRedirecting] = useState(false);

    async function getPortalLink(): Promise<any> {
        const functions = getFunctions(firebaseApp, "europe-west2");
        const createPortalLinkFunction = httpsCallable(
            functions,
            "ext-firestore-stripe-payments-createPortalLink",
        );

        const { data } = await createPortalLinkFunction({
            returnUrl: `${window.location.href}`,
        });

        return data;
    }

    async function redirectToCustomerPortal() {
        setIsGettingToken(true);
        const data = await getPortalLink();
        setIsRedirecting(true);
        window.location.assign(data.url);
    }

    async function redirectToSubscriptionReactivationPage(subscriptionId: string) {
        setIsGettingToken(true);
        const data = await getPortalLink();
        setIsRedirecting(true);
        const subscriptionUrl = `${data.url}/subscriptions/${subscriptionId}/reactivate`;
        window.location.assign(subscriptionUrl);
    }

    async function redirectToSubscriptionPaymentLink(subscriptionId: string) {
        if (!user) return;
        setIsRedirecting(true);
        const link = await api.getSubscriptionPaymentLink(user.token, subscriptionId);
        window.open(link, "_blank")?.focus();
        setIsRedirecting(false);
    }

    return {
        redirectToCustomerPortal,
        redirectToSubscriptionReactivationPage,
        redirectToSubscriptionPaymentLink,
        isGettingToken,
        isRedirecting,
    };
}
