import ReactGA from "react-ga";
import { createFunctionWithTimeout } from "../utils";

export default function sendPageview(
	page: string,
	hitCallbackFunction?: Function
) {
	if (!page.startsWith("/")) page = `/${page}`;

	//ReactGA.set({ page: page });
	ReactGA.ga("send", "pageview", page, {
		hitCallback: () => {
			if (hitCallbackFunction)
				return createFunctionWithTimeout(hitCallbackFunction);
		},
	});
}
