export default function UnderMaintenance() {
    return (
        <>
            <div className="mt-5 ms-auto me-auto text-center">
                <a href="https://www.apponfly.com" rel="noopener">
                    <img src="/logo-large.png" height="100" className="mt-5" alt="logo" />
                </a>
                <h2 className="mt-3">We'll be back.</h2>
                <p className="mt-3">
                    We are busy updating AppOnFly for you. <br /> Please check back soon.
                </p>
            </div>
        </>
    );
}
