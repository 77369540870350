import { useState, useContext } from "react";
import firebaseApp from "../Firebase/firebase";
import { getFirestore, collection, addDoc, onSnapshot } from "firebase/firestore";
import { UserContext } from "../Authentication/UserContext";
import sendPageview from "../GoogleAnalytics/ga";
import { IPrice } from "../Products/Price";
import { SUBSCRIPTION_TYPE } from "../Subscriptions/subscriptionType";

export default function usePaymentLink() {
    const [isRedirecting, setIsRedirecting] = useState(false);
    const userContext = useContext(UserContext);

    async function redirectToPayment(price: IPrice) {
        setIsRedirecting(true);

        if (userContext.user) {
            const firestore = getFirestore(firebaseApp);
            const checkoutSessionDocRef = await addDoc(
                collection(firestore, `customers/${userContext.user.id}/checkout_sessions`),
                {
                    price: price.id,
                    customerEmail: userContext.user.email,
                    allow_promotion_codes: true,
                    mode: price.subscriptionType === SUBSCRIPTION_TYPE.PAYG ? "payment" : "subscription",
                    success_url: `${window.location.origin}?stripe-payment-successful`,
                    cancel_url: window.location.href,
                },
            );

            onSnapshot(checkoutSessionDocRef, async (snap) => {
                const data = snap.data();
                if (data) {
                    if (data.error) {
                        alert(`Stripe error: ${data.error.message}`);
                        setIsRedirecting(false);
                    }
                    if (data.url) {
                        sendPageview("subscribe-stripe-redirect", () => window.location.assign(data.url));
                    }
                }
            });
        }
    }

    return { redirectToPayment, isRedirecting };
}
