export function setCookie(name: string, value: string, expiration: Date) {
	document.cookie =
		name +
		"=" +
		value +
		(expiration ? ";expires=" + expiration.toUTCString() : "") +
		";domain=.apponfly.com;path=/";
}

export function clearCookie(name: string) {
	setCookie(name, "", new Date(0));
}

export function getCookie(cname: string) {
	var name = cname + "=";
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(";");
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}
