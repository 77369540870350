import useCustomerPortal from "./useCustomerPortal";

export default function CustomerPortalButton(props: ICustomerPortalButtonProps) {
    const { redirectToCustomerPortal, isGettingToken, isRedirecting } = useCustomerPortal();

    const loadingTitle = isGettingToken ? "Getting Stripe access token..." : "Redirecting to Stripe...";
    const isLoading = isGettingToken || isRedirecting;

    return (
        <button
            className={`${props.className} ${isLoading ? "disabled" : ""}`}
            type="button"
            onClick={redirectToCustomerPortal}
        >
            {isLoading && <span className="spinner-border spinner-border-sm me-2" role="status"></span>}
            {isLoading ? loadingTitle : props.title ?? "Billing & Subscriptions"}
        </button>
    );
}

interface ICustomerPortalButtonProps {
    title?: string;
    className?: string;
}
