import useCustomerPortal from "./useCustomerPortal";

export default function PaymentLinkButton(props: IPaymentLinkButtonProps) {
	const { redirectToSubscriptionPaymentLink, isRedirecting } =
		useCustomerPortal();

	return (
		<button
			className={`${props.className} ${isRedirecting ? "disabled" : ""}`}
			type="button"
			onClick={() =>
				redirectToSubscriptionPaymentLink(props.subscriptionId)
			}
		>
			{isRedirecting ? "Redirecting to Stripe..." : "Renew subscription"}
		</button>
	);
}

interface IPaymentLinkButtonProps {
	subscriptionId: string;
	className?: string;
}
