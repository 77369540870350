import { useContext, useEffect, useState } from "react";
import firebaseApp from "../Firebase/firebase";
import { collection, getFirestore, onSnapshot, query, where, doc, getDocs, Firestore } from "firebase/firestore";
import { checkVmStatusForSubscription } from "../api";
import { UserContext } from "../Authentication/UserContext";
import { SubscriptionModel } from "./Subscription";
import { SUB_STATUS } from "./subStatus";
import { VM_STATUS } from "./vmStatus";
import { SUBSCRIPTION_TYPE } from "./subscriptionType";

export default function useSubscriptions() {
    const { user } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(true);
    const [subscriptions, setSubscriptions] = useState<SubscriptionModel[]>([]);

    useEffect(() => {
        if (!user) return;

        const firestore = getFirestore(firebaseApp);

        const customerSubscriptionsQuery = query(
            collection(firestore, `customers/${user.id}/subscriptions`),
            where("status", "in", [SUB_STATUS.ACTIVE, SUB_STATUS.INCOMPLETE, SUB_STATUS.CANCELED, SUB_STATUS.PAST_DUE]),
        );

        const unsubscribe = onSnapshot(customerSubscriptionsQuery, (snapshot) => {
            let subscriptions: SubscriptionModel[] = [];
            snapshot.docs.forEach(async (subscriptionDocument) => {
                const data = subscriptionDocument.data();
                const price = data.items[0].price;
                const model: SubscriptionModel = {
                    id: subscriptionDocument.id,
                    priceId: price.id,
                    totalHours: price?.recurring.usage_type === "metered" ? await getTotalHours(price, firestore) : 0,
                    productName: price.product.name,
                    status: data.status,
                    start: new Date(data.current_period_start.seconds * 1000),
                    end: new Date(data.current_period_end.seconds * 1000),
                    cancelAt: data.cancel_at ? new Date(data.cancel_at.seconds * 1000) : undefined,
                    vmStatus: VM_STATUS.RUNNING,
                    isAutoShutdownEnabled: false,
                    urlKey: price.product.metadata.urlKey,
                    subscriptionType:
                        data.items[0]?.plan?.usage_type === "metered" ? SUBSCRIPTION_TYPE.PAYG : SUBSCRIPTION_TYPE.FLAT,
                    virtualMachineId: data.metadata.virtualMachineId
                };

                subscriptions.push(model);
                if (data.metadata.virtualMachineId) {
                    const vmDocRef = doc(firestore, "virtualMachines", data.metadata.virtualMachineId);
                    onSnapshot(vmDocRef, (vmSnapshot) => {
                        let sub = subscriptions.find((s) => s.id === subscriptionDocument.id);
                        if (sub) {
                            sub.vmStatus = vmSnapshot?.data()?.status;
                            sub.passwordResetResult = {
                                isSuccess: vmSnapshot?.data()?.passwordResetResult?.isSuccess,
                                timestamp: vmSnapshot?.data()?.passwordResetResult?.timestamp.toDate(),
                            };
                        }

                        if (subscriptions.length === snapshot.docs.length) {
                            //divný, ale zatím jsem to líp nevymyslel
                            setSubscriptions([...subscriptions]);
                            setIsLoading(false);
                        }
                    });

                    if (model.status !== SUB_STATUS.CANCELED) {
                        (async () => {
                            await checkVmStatusForSubscription(user.token, subscriptionDocument.id);
                        })();
                    }
                } else {
                    model.vmStatus = "";
                }
            });

            if (snapshot.docs.length === 0) {
                setIsLoading(false);
            }
        });

        return () => unsubscribe();
    }, [user]);

    return {
        subscriptions,
        isLoading,
    };
}

async function getTotalHours(price: any, firestore: Firestore): Promise<number> {
    const getActivePriceQuery = query(
        collection(firestore, `products/${price.product.id}/prices`),
        where("active", "==", true),
        where("type", "==", "one_time"),
        where("metadata.usageBasedPriceId", "==", price.id),
    );

    const oneTimePrice = await getDocs(getActivePriceQuery);
    let hours = 0;
    oneTimePrice.forEach((onePrice) => {
        const data = onePrice.data();
        hours = data.metadata?.hours;
    });
    return hours;
}
