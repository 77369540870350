// FirebaseUI (v6.0.0) podporuje pouze Firebase v9 compat.
// TODO: Až bude nová verze FirebaseUI s plnou podporou Firebase v9 modular,
// import zde nahradit (a také v SignUp komponentě)
import firebase from "firebase/compat/app";

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = firebase.initializeApp(config);
export default firebaseApp;
