import { useState, useEffect, FormEvent, useContext } from "react";
import firebaseApp from "../Firebase/firebase";
import {
    getFirestore,
    query,
    collection,
    where,
    getDocs,
    QueryDocumentSnapshot,
    DocumentData,
} from "firebase/firestore";
import { UserContext } from "../Authentication/UserContext";
import usePaymentLink from "../Stripe/usePaymentLink";
import preferences from "../Subscriptions/SubscribePreferences";
import useApplicationKey from "../ApplicationKey";
import LoggedInUserPanel from "../User/LoggedInUserPanel";
import sendPageview from "../GoogleAnalytics/ga";
import { IPrice } from "../Products/Price";
import PriceBanner from "../Products/PriceBanner";
import { SUBSCRIPTION_TYPE } from "../Subscriptions/subscriptionType";

export default function Subscribe() {
    const { user } = useContext(UserContext);
    const { key } = useApplicationKey();
    const lastPreferences = preferences.get();

    if (key) {
        lastPreferences.productId = "";
        lastPreferences.priceId = "";
    }

    const [selectedSubscriptionType, setSelectedSubscriptionType] = useState<string>(
        lastPreferences.subscriptionType === "" ? SUBSCRIPTION_TYPE.PAYG : lastPreferences.subscriptionType,
    );
    const [products, setProducts] = useState<IProduct[]>([]);
    const [prices, setPrices] = useState<IPrice[]>([]);
    const [currencies, setCurrencies] = useState<string[]>([]);
    const [selectedProductId, setSelectedProductId] = useState<string>(lastPreferences.productId ?? "");
    const [selectedCurrency, setSelectedCurrency] = useState<string>(user?.currency ?? "");

    const { redirectToPayment } = usePaymentLink();

    const handlePriceSelect = (price: IPrice) => {
        if (price) {
            preferences.set({
                productId: selectedProductId,
                priceId: price.id,
                currency: selectedCurrency,
                subscriptionType: selectedSubscriptionType,
            });
            redirectToPayment(price);
        }
    };

    useEffect(() => {
        sendPageview("subscribe-display");
    }, []);

    useEffect(() => {
        async function loadPrices(productId: string) {
            const product = products.find((p) => p.id === productId);
            console.log('product', product)
            if (product) {
                const items = new Array<IPrice>();
                const newCurrencies = new Array<string>();

                let allPricesDocs;
                if (selectedSubscriptionType === SUBSCRIPTION_TYPE.FLAT) {
                    const getActiveFlatPricesQuery = query(
                        collection(product.entity.ref, "prices"),
                        where("active", "==", true),
                        where("recurring.usage_type", "==", "licensed"),
                    );
                    allPricesDocs = await getDocs(getActiveFlatPricesQuery);
                } else {
                    const getActivePayAsYouGoPricesQuery = query(
                        collection(product.entity.ref, "prices"),
                        where("active", "==", true),
                        where("recurring", "==", null),
                    );
                    allPricesDocs = await getDocs(getActivePayAsYouGoPricesQuery);
                }

                allPricesDocs.forEach((price) => {
                    const data = price.data();

                    items.push({
                        id: price.id,
                        name: data.name,
                        amount: data.unit_amount / 100,
                        currency: data.currency,
                        interval: data.interval,
                        subscriptionType: data.recurring ? SUBSCRIPTION_TYPE.FLAT : SUBSCRIPTION_TYPE.PAYG,
                        usageBasedPriceId: data.metadata?.usageBasedPriceId,
                        hours: data.metadata?.hours,
                    });

                    if (!newCurrencies.includes(data.currency)) {
                        newCurrencies.push(data.currency);
                    }
                });

                items.sort((a, b) => a.amount - b.amount);

                let currencyToSetDefaultPrice = selectedCurrency;

                setCurrencies(newCurrencies);
                if (newCurrencies.length > 0) {
                    if (!newCurrencies.includes(selectedCurrency)) {
                        if (newCurrencies.includes(lastPreferences.currency)) {
                            currencyToSetDefaultPrice = lastPreferences.currency;
                        } else {
                            currencyToSetDefaultPrice = newCurrencies.find((c) => c === (user?.currency ?? "usd")) ?? "";
                            if (!currencyToSetDefaultPrice) {
                                currencyToSetDefaultPrice = newCurrencies[0];
                            }
                        }

                        setSelectedCurrency(currencyToSetDefaultPrice);
                    }
                } else {
                    setSelectedCurrency("");
                }

                if (JSON.stringify(prices) !== JSON.stringify(items)) {
                    setPrices(items);
                }
            }
        }

        if (selectedProductId) {
            loadPrices(selectedProductId);
        }

        let currentCurrencyPriceItems = prices.filter((price) => price.currency === selectedCurrency);

        let selectedPrice = null;
        if (lastPreferences.priceId) {
            selectedPrice = currentCurrencyPriceItems.find((p) => p.id === lastPreferences.priceId);
        }

        if (!selectedPrice && prices.length > 0) {
            selectedPrice = currentCurrencyPriceItems[0];
        }
    }, [
        products,
        selectedProductId,
        selectedCurrency,
        selectedSubscriptionType,
        prices,
        lastPreferences.priceId,
        lastPreferences.currency,
        lastPreferences.subscriptionType,
    ]);

    useEffect(() => {
        async function loadProducts() {
            const items = new Array<IProduct>();
            const firestore = getFirestore(firebaseApp);
            const getActiveProductQuery = query(collection(firestore, "products"), where("active", "==", true));
            const querySnapshot = await getDocs(getActiveProductQuery);
            querySnapshot.forEach((product) => {
                const data = product.data();
                items.push({
                    id: product.id,
                    name: data.name,
                    urlKey: data.metadata.urlKey,
                    order: parseInt(data.metadata.order),
                    entity: product,
                });
            });

            // User has selected currency in stripe, remove every item without valid currency-prices combo
            if (user?.currency) {
                const removePromises = items.map(async (product) => {
                    let allPricesDocs;
                    const getActiveFlatPricesQuery = query(
                        collection(product.entity.ref, "prices"),
                        where("active", "==", true),
                        where("currency", "==", user.currency),
                    );
                    allPricesDocs = await getDocs(getActiveFlatPricesQuery);
                    if (allPricesDocs.empty) {
                        items.splice(items.indexOf(product, 0), 1);
                    }
                });

                await Promise.all(removePromises)
            }

            setProducts(items.sort((i, i2) => i.order - i2.order));

            let selectedProduct = null;
            if (lastPreferences.productId) {
                selectedProduct = items.find((p) => p.id === lastPreferences.productId);
            }

            if (!selectedProduct) {
                selectedProduct = items.find((p) => p.urlKey === key);
            }

            if (selectedProduct) {
                setSelectedProductId(selectedProduct.id);
            } else {
                setSelectedProductId(items[0].id);
            }
        }

        loadProducts();
    }, [key, lastPreferences.productId, user?.currency]);

    async function onFormSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
    }

    return (
        <>
            <div className="container mt-2">
                <LoggedInUserPanel isOnlyCancelVisible={true} />
            </div>
            <form className="mt-3" onSubmit={onFormSubmit}>
                <div className="container mt-3">
                    {!user?.currency && (
                        <div className="text-end mb-2">
                            <select
                                className="form-select form-select-transparent w-auto d-inline"
                                onChange={(event) => setSelectedCurrency(event.target.value)}
                                value={selectedCurrency}
                            >
                                {currencies.map((currency) => {
                                    return (
                                        <option key={currency} value={currency}>
                                            {currency}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    )}
                    <div>
                        <div style={{ textAlign: "center" }}>
                            <h1 className="h2">Subscribe to AppOnFly</h1>
                            <div className="mt-5 text-center">
                                <select
                                    className="form-select w-auto-responsive d-inline"
                                    onChange={(event) => {
                                        window.history.replaceState(null, "", "subscribe");
                                        setSelectedProductId(event.target.value);
                                    }}
                                    value={selectedProductId}
                                >
                                    {products.map((product) => {
                                        return (
                                            <option key={product.id} value={product.id}>
                                                {product.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                            <div style={{ marginTop: "46px" }}>
                                <div style={{ marginBottom: "86px" }}>
                                    <input
                                        type="checkbox"
                                        id="toggle"
                                        className="toggleCheckbox"
                                        checked={selectedSubscriptionType === SUBSCRIPTION_TYPE.FLAT}
                                    />
                                    <label htmlFor="toggle" className="toggleContainer">
                                        <div onClick={() => setSelectedSubscriptionType(SUBSCRIPTION_TYPE.PAYG)}>
                                            Flexible
                                        </div>
                                        <div onClick={() => setSelectedSubscriptionType(SUBSCRIPTION_TYPE.FLAT)}>
                                            Fixed
                                        </div>
                                    </label>
                                </div>

                                {prices
                                    .filter(
                                        (price) =>
                                            price.currency === selectedCurrency &&
                                            price.subscriptionType === selectedSubscriptionType,
                                    )
                                    .reduce<Array<Array<IPrice>>>((rows, price, index) => {
                                        if (index % 3 === 0) rows.push([]);
                                        if (rows.length > 0) rows[rows.length - 1].push(price);
                                        return rows;
                                    }, [])
                                    .map((row, rowIndex, rows) => (
                                        <div key={rowIndex} className="price-container my-3">
                                            {row.map((price, priceIndex) => {
                                                // get the first price in each row and set it as the cheapest price
                                                const cheapestPrice = row[0];
                                                const id = "price-" + price.id;
                                                const isOnlyPriceInGroup = row.length === 1;
                                                const isMiddlePriceInGroup =
                                                    row.length > 1 && priceIndex === Math.floor(row.length / 2);

                                                return (
                                                    <div key={price.id} className="mb-4">
                                                        <div className="">
                                                            <div id={id}>
                                                                <PriceBanner
                                                                    {...price}
                                                                    onPriceSelect={handlePriceSelect}
                                                                    isMostPopular={
                                                                        isOnlyPriceInGroup || isMiddlePriceInGroup
                                                                    }
                                                                    cheapestPlan={cheapestPrice}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ))}
                                {prices.filter(
                                    (price) =>
                                        price.currency === selectedCurrency &&
                                        price.subscriptionType === selectedSubscriptionType,
                                ).length === 0 && (
                                    <div className="row my-3">
                                        <div className="col-md-12">
                                            <p>
                                                There are no prices available for the selected subscription type. Please
                                                select&nbsp;
                                                <span
                                                    style={{
                                                        textDecoration: "underline",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        setSelectedSubscriptionType(
                                                            selectedSubscriptionType === SUBSCRIPTION_TYPE.PAYG
                                                                ? SUBSCRIPTION_TYPE.FLAT
                                                                : SUBSCRIPTION_TYPE.PAYG,
                                                        )
                                                    }
                                                >
                                                    another option
                                                </span>
                                                .
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {selectedSubscriptionType !== SUBSCRIPTION_TYPE.PAYG && (
                            <div style={{ marginBottom: "50px" }}>
                                <h3 className="h5 mb-3">FAQ</h3>

                                <h4 className="fs-7 fw-bold mb-0">How do you charge for the VPS?</h4>
                                <p className="fs-7">
                                    We charge monthly or yearly flat fee, no matter how much time is the VPS up and
                                    running.
                                </p>
                                <h4 className="fs-7 fw-bold mb-0">What if I run out of time?</h4>
                                <p className="fs-7">
                                    Your subscription will be automatically renewed at the end of subscription period.
                                </p>
                                <h4 className="fs-7 fw-bold mb-0">Can I cancel my subscription?</h4>
                                <p className="fs-7">
                                    You can cancel your subscription any time before it's automatic renewal.
                                </p>
                            </div>
                        )}
                        {selectedSubscriptionType !== SUBSCRIPTION_TYPE.FLAT && (
                            <div style={{ marginBottom: "50px" }}>
                                <h3 className="h5 mb-3">FAQ</h3>
                                <h4 className="fs-7 fw-bold mb-0">How do you charge for the VPS?</h4>
                                <p className="fs-7">
                                    We charge for the VPS only when it is up and running. Because every cent counts,
                                    billing is by the minute.
                                </p>
                                <h4 className="fs-7 fw-bold mb-0">Is there a minimum monthly commitment?</h4>
                                <p className="fs-7">
                                    At minimum we charge 10, 25 or 50 hours/month for 100, 250 or 500 Hours plan
                                    respectively.
                                </p>
                                <h4 className="fs-7 fw-bold mb-0">What if I run out of time?</h4>
                                <p className="fs-7">Your subscription will be automatically renewed.</p>
                                <h4 className="fs-7 fw-bold mb-0">Can I cancel my subscription?</h4>
                                <p className="fs-7">
                                    You can cancel your subscription any time before it's automatic renewal.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </form>
        </>
    );
}

interface IProduct {
    id: string;
    name: string;
    urlKey: string;
    order: number;
    entity: QueryDocumentSnapshot<DocumentData>;
}
