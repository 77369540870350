export const VM_STATUS = {
    PENDING_CREATION: "PendingCreation",
    CREATING: "Creating",
    STARTING: "Starting",
    RUNNING: "Running",
    STOPPING: "Stopping",
    STOPPED: "Stopped",
    DELETING: "Deleting",
    RESETTING_TO_SNAPSHOT: "ResetingToSnapshot",
    RESETTING_TO_FACTORY: "ResetingToFactory",
    CHANGING_PASSWORD: "ChangingPassword",
    UNHEALTHY: "Unhealthy"
};
