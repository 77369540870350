import { useEffect, useState } from "react";

export default function useApplicationKey() {
	const defaultKey = "microsoft-windows-vps";
	const [key, setKey] = useState<string | null>(null);
	const [isKeyLoaded, setIsKeyLoaded] = useState(false);
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		let keyParam = urlParams.get("app");
		keyParam = rewriteValue(keyParam);
		setKey(keyParam);
		window.history.replaceState(
			{},
			"",
			`${window.location.origin}${window.location.pathname}`
		);
		setIsKeyLoaded(true);
	}, []);

	function rewriteValue(app: string | null) {
		if (app) {
			switch (app) {
				case "5":
					app = "microsoft-word";
					break;
				case "6":
					app = "microsoft-excel";
					break;
				case "7":
					app = "microsoft-outlook";
					break;
				case "8":
					app = "microsoft-powerpoint";
					break;
				case "9":
					app = "microsoft-access";
					break;
				case "10":
					app = "microsoft-publisher";
					break;
				case "12":
					app = "microsoft-visio";
					break;
				case "16":
					app = "microsoft-office-standard";
					break;
				case "17":
					app = "microsoft-office-professional";
					break;
				case "18":
					app = "microsoft-project";
					break;
				default:
					break;
			}
		}

		return app;
	}
	return { key, defaultKey, isKeyLoaded };
}
