import { useEffect, useState } from "react";
import useTrial, { ErrorStatus } from "../Trials/useTrial";
import firebaseApp from "../Firebase/firebase";
import { getFirestore, query, collection, where, getDocs, QuerySnapshot, DocumentData } from "firebase/firestore";
import LoadingButton from "../Components/LoadingButton";
import useApplicationKey from "../ApplicationKey";

export default function Trial() {
    const { assignTrial, isLooking, isRedirecting, errorStatus } = useTrial();
    const { key, defaultKey, isKeyLoaded } = useApplicationKey();
    const [product, setProduct] = useState<IProduct | null>(null);
    const [showMore, setShowMore] = useState<boolean>(false);

    useEffect(() => {
        let currentAppKey: string | null;

        async function loadProductByKey(): Promise<QuerySnapshot<DocumentData>> {
            const firestore = getFirestore(firebaseApp);
            let getProductsQuery = query(
                collection(firestore, "products"),
                where("active", "==", true),
                where("metadata.urlKey", "==", key),
            );
            let querySnapshot = await getDocs(getProductsQuery);

            if (querySnapshot.empty) {
                getProductsQuery = query(
                    collection(firestore, "products"),
                    where("active", "==", true),
                    where("metadata.urlKey", "==", defaultKey),
                );
                querySnapshot = await getDocs(getProductsQuery);
                currentAppKey = defaultKey;
            } else {
                currentAppKey = key;
            }

            return querySnapshot;
        }

        async function loadProduct() {
            let querySnapshot = await loadProductByKey();

            querySnapshot.forEach((product) => {
                const data = product.data();
                if (data.metadata.trialBufferKey && data.metadata.urlKey === currentAppKey) {
                    setProduct({
                        name: data.name,
                        bufferKey: data.metadata.trialBufferKey,
                        urlKey: data.metadata.urlKey,
                    });
                }
            });
        }
        if (isKeyLoaded) loadProduct();
    }, [key, defaultKey, isKeyLoaded]);

    useEffect(() => tryAssignTrial(), [product]); // eslint-disable-line react-hooks/exhaustive-deps

    function tryAssignTrial() {
        if (product) {
            setShowMore(false);
            assignTrial(product.bufferKey, product.urlKey);
        }
    }

    const isLoading = isLooking || isRedirecting;
    const title = product ? `Start trial for ${product.name}` : "Start trial";
    const loadingTitle = isLooking ? "Looking for a trial machine..." : "Redirecting to guacamole...";

    let errorMessage = null;
    let hasMore = false;
    if (errorStatus) {
        if (errorStatus.status === ErrorStatus.AlreadyTrialing) {
            errorMessage = "You are already trialing AppOnFly.\nWe can't start another trial within here.";
        } else if (errorStatus.status === ErrorStatus.OutOfTrialVms) {
            errorMessage = "Unfortunately, all trials are busy. \nPlease subscribe or wait for next free slot.";
        } else if (errorStatus.status === ErrorStatus.RepeatedLimits) {
            errorMessage =
                "Trial limit for your IP address has been reached.\nPlease subscribe or wait for the next free slot.";
            hasMore = true;
        }
    }

    return (
        <div className="container mt-4 text-center">
            <div className="mb-2">
                {errorMessage && (
                    <div className="alert alert-danger d-inline-block mb-1">
                        {errorMessage}
                        &nbsp;
                        {hasMore && !showMore && (
                            <a
                                href="#see-more"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowMore(true);
                                }}
                            >
                                See more
                            </a>
                        )}
                    </div>
                )}
                {errorStatus && hasMore && showMore && (
                    <div className="mb-2">
                        Trials assigned for your IP address {errorStatus.yourIp}:
                        {errorStatus?.assignments?.map((assigment) => {
                            return <div>{assigment.toLocaleString()}</div>;
                        })}
                    </div>
                )}
            </div>
            <div>
                {!errorStatus && (
                    <LoadingButton
                        title={title}
                        loadingTitle={loadingTitle}
                        isLoading={isLoading}
                        onClick={async () => {
                            tryAssignTrial();
                        }}
                    />
                )}
                {errorStatus && (
                    <>
                        {errorStatus.status === ErrorStatus.AlreadyTrialing && (
                            <a className="btn btn-primary" href="https://www.apponfly.com">
                                OK
                            </a>
                        )}
                        {errorStatus.status !== ErrorStatus.AlreadyTrialing && (
                            <a className="btn btn-primary" href={"/subscribe" + (key !== "vps" ? "?app=" + key : "")}>
                                Subscribe now
                            </a>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

interface IProduct {
    name: string;
    urlKey: string;
    bufferKey: string;
}
