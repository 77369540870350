import { NavLink } from "react-router-dom";
import Subscription, { SubscriptionModel } from "./Subscription";
import { SUB_STATUS } from "./subStatus";
import useSubscriptions from "./useSubscriptions";

const Subscriptions = (props: SubscriptionsProps) => {
	const { subscriptions, isLoading } = useSubscriptions();

	if (isLoading) return null;

	let nonCancelledSubscriptions: SubscriptionModel[] = subscriptions.filter(
		(s) =>
			s.status !== SUB_STATUS.CANCELED &&
			s.status !== SUB_STATUS.INCOMPLETE &&
			s.status !== SUB_STATUS.INCOMPLETE_EXPIRED
	);

	let canceledSubscriptions: SubscriptionModel[] = [];
	subscriptions
		.filter(
			(s) =>
				s.status === SUB_STATUS.CANCELED &&
				subscriptions.filter(
					(s2) =>
						s2.status !== SUB_STATUS.CANCELED &&
						s2.status !== SUB_STATUS.INCOMPLETE &&
						s2.status !== SUB_STATUS.INCOMPLETE_EXPIRED &&
						s2.productName === s.productName
				).length === 0
		)
		.forEach((s) => {
			if (
				!canceledSubscriptions.find(
					(s2) => s2.productName === s.productName
				)
			) {
				canceledSubscriptions = [...canceledSubscriptions, s];
			}
		});

	let isEmpty =
		subscriptions.length === 0 && canceledSubscriptions.length === 0;

	return (
		<>
			{isEmpty ? (
				<div className="d-flex justify-content-center pt-5">
					<p>It is lonely here.</p>
				</div>
			) : (
				<>
					{nonCancelledSubscriptions.map((subscription, i) => (
						<div
							key={subscription.id}
							className="card shadow border border-dark my-4"
						>
							<Subscription
								model={subscription}
							/>
						</div>
					))}
					{canceledSubscriptions.map((cancelledSubscription) => (
						<div
							key={cancelledSubscription.id}
							className="card shadow-sm my-4"
						>
							<Subscription
								model={cancelledSubscription}
							/>
						</div>
					))}
				</>
			)}
			<div className="container mt-5 p-4">
				<div className="row">
					<div className="col-md-4 offset-md-4">
						<NavLink
							className="btn btn-primary btn-fix-size btn-rounded"
							to={"/subscribe"}
						>
							Add new subscription
						</NavLink>
					</div>
				</div>
			</div>
		</>
	);
};

export type SubscriptionsProps = {
};

export default Subscriptions;
