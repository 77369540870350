import React, { useState, useEffect, useContext } from "react";
import {
  doc,
  getDoc,
  getFirestore,
  Timestamp,
  setDoc,
} from "firebase/firestore";
import firebaseApp from "../Firebase/firebase";
import { UserContext } from "../Authentication/UserContext";
import { VM_STATUS } from "../Subscriptions/vmStatus";

const ShutdownTimer = ({subscriptionId, vmStatus }: ShutdownTimerProps) => {
  const [shutdownTime, setShutdownTime] = useState("-");
  const [newShutdownTime, setNewShutdownTime] = useState<Date | null>(null);
  const [autoshutdown, setAutoshutdown] = useState(false);
  const firestore = getFirestore(firebaseApp);
  const { user } = useContext(UserContext);

  const toggleAutoShutdown = async (subId: string, enable: boolean) => {
    const docPath = `customers/${user?.id}/shutdowns/${subId}`;
    const docRef = doc(firestore, docPath);
    const currentTime = new Date();

    const customerDocSnapshot = await getDoc(docRef);
    let defaultTime = 181 * 60 * 1000;

    if (customerDocSnapshot.exists()) {
      const { defaultAutoShutdownTime, autoShutdownTime } =
        customerDocSnapshot.data() || {};
        defaultTime = (toMinutes(defaultAutoShutdownTime) + 1) * 60 * 1000;

        if (enable && (!shutdownTime || !autoShutdownTime))
        {
          console.log('shutdown time', new Date(currentTime.getTime() + defaultTime))
          await setDoc(
            docRef,
            {
              autoShutdownTime: Timestamp.fromDate(
                new Date(currentTime.getTime() + defaultTime)
              ),
              id: subId,
            },
            { merge: true }
          );
        }
        
    
        if (!enable) {
          await setDoc(
            docRef,
            {
              autoShutdownTime: null,
            },
            { merge: true }
          );
          setShutdownTime("-");
        }
    }
    setAutoshutdown(enable);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (vmStatus === VM_STATUS.STOPPED) {
        toggleAutoShutdown(subscriptionId, false);
      }
  
      if (
        vmStatus === VM_STATUS.RUNNING &&
        !autoshutdown
      ) {
        const docPath = `customers/${user?.id}/shutdowns/${subscriptionId}`;
        const docRef = doc(firestore, docPath);
  
        try {
          const customerDocSnapshot = await getDoc(docRef);
  
          if (customerDocSnapshot.exists()) {
            const { defaultAutoShutdownEnabled, defaultAutoShutdownTime } =
              customerDocSnapshot.data() || {};
  
            if (defaultAutoShutdownTime && defaultAutoShutdownEnabled) {
              setShutdownTime(defaultAutoShutdownTime);
            }
            toggleAutoShutdown(subscriptionId, defaultAutoShutdownEnabled);
          }
        } catch (error) {
          console.error("Error fetching autoShutdown data:", error);
        }
      }
    };
  
    fetchData();
  
  }, [vmStatus, subscriptionId, autoshutdown, user, firestore, toggleAutoShutdown]);
  
  

  useEffect(() => {
    const fetchAutoShutdownTime = async () => {
      try {
        if (user) {
          const docPath = `customers/${user.id}/shutdowns/${subscriptionId}`;
          const docRef = doc(firestore, docPath);
          const customerDocSnapshot = await getDoc(docRef);

          if (customerDocSnapshot.exists()) {
            const { autoShutdownTime } =
              customerDocSnapshot.data() || {};

            if (autoShutdownTime) {
              const currentTime = autoShutdownTime.toDate();
              const now = new Date();
              const timeDifference = currentTime.getTime() - now.getTime();
              const hours = Math.floor(timeDifference / (1000 * 60 * 60));
              const minutes = Math.floor(
                (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
              );

              if (hours >= 0 && minutes >= 0) {
                setShutdownTime(`${hours}h ${minutes}m`);
              } else {
                setShutdownTime("-"); // Display as "0h 0m" for negative times
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching autoShutdownTime:", error);
      }
    };

    fetchAutoShutdownTime();
    const interval = setInterval(fetchAutoShutdownTime, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [user, autoshutdown, subscriptionId, firestore]);

  useEffect(() => {
    const updateAutoShutdownTime = async (
      subscriptionId: any,
      newAutoShutdownTime: Date
    ) => {
      try {
        if (user && autoshutdown) {
          const docPath = `customers/${user.id}/shutdowns/${subscriptionId}`;
          const docRef = doc(firestore, docPath);
          await setDoc(
            docRef,
            {
              autoShutdownTime: Timestamp.fromDate(newAutoShutdownTime),
              id: subscriptionId,
            },
            { merge: true }
          );
         }
      } catch (error) {
        console.error("Error updating autoShutdownTime:", error);
      }
    };

    if (newShutdownTime) {
      updateAutoShutdownTime(subscriptionId, newShutdownTime);
    }
  }, [user, autoshutdown, subscriptionId, newShutdownTime, firestore]);

  const changeTime = (type: string) => {
    if (shutdownTime) {
      const regex = /(\d{1,2}[hm]\s{0,1}){1,2}$/g;
      const validDate = regex.test(shutdownTime);

      if (validDate) {
        let minutes = toMinutes(shutdownTime);
        
        if (type === "plus") {
          //1440 = 24 hours, maximum
          if (minutes < 1440) {
            if (minutes % 30 !== 0) {
              // Round minutes up to the nearest half-hour
              minutes = Math.ceil(minutes / 30) * 30;
            } else {
              minutes = minutes + 30;
            }
          }
        } else {
          if (minutes > 30) {
            if (minutes % 30 !== 0) {
              // Round minutes down to the nearest half-hour
              minutes = Math.floor(minutes / 30) * 30;
            } else {
              minutes = minutes - 30;
            }
          }
        }

        if (minutes < 0) {
          // If minutes go negative, reset to 0h 0m
          setShutdownTime("-");
          setNewShutdownTime(null);
        } else {
          // Handle positive minutes by adjusting hours
          let hours = Math.floor(minutes / 60);
          minutes = minutes % 60;

          const times = { hours, minutes };
          setShutdownTime(`${times.hours}h ${times.minutes}m`);

          const currentDate = new Date();
          currentDate.setHours(currentDate.getHours() + hours);
          currentDate.setMinutes(currentDate.getMinutes() + minutes);
          setNewShutdownTime(currentDate);
        }
      } else {
        setShutdownTime("-");
      }
    }
  };

  const roundTime = (time: number, minutesToRound: number) => {
    const rounded = Math.round(time / minutesToRound) * minutesToRound;
    let roundedHours = Math.floor(rounded / 60);
    let roundedMinutes = rounded % 60;

    if (roundedHours < 0 && roundedMinutes < 0) {
      roundedHours = 0;
      roundedMinutes = 0;
    }

    if (roundedHours >= 24 && roundedMinutes === 30) {
      roundedHours = 24;
      roundedMinutes = 0;
    }

    return { hours: roundedHours, minutes: roundedMinutes };
  };

  const toMinutes = (ts: string) =>
    ts.split(" ").reduce((acc: number, cur: string) => {
      const num = parseInt(cur);
      const [isHour, isMinute] = [cur.endsWith("h"), cur.endsWith("m")];
      return num * (isHour ? 60 : 1) + acc;
    }, 0);

  return (
    <div className="row g-3 align-items-center">
      {(
        <div className="col-auto">
          <label className={"fs-5 " + (!autoshutdown ? "label-disabled" : "")}>Shutdown in: </label>
        </div>
      )}
      <div className="col-auto">
        <div className="input-group input-group-sm shutdown">
          <button
            className="btn btn-outline-primary btn-rounded-start"
            style={!autoshutdown ? { color: "#999", borderColor: "#999", pointerEvents: "none", cursor: "not-allowed" } : {}}
            onClick={() => changeTime("minus")}
            disabled={!autoshutdown}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-dash"
              viewBox="0 0 16 16"
            >
              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
            </svg>
          </button>
          <input
            value={autoshutdown ? shutdownTime : '-'}
            onChange={(e) => setShutdownTime(e.target.value)}
            type="text"
            id="ev_time"
            style={{ width: "5em" }}
            className="form-control text-center"
            name="time"
            placeholder={autoshutdown ? shutdownTime : '0h'}
            autoComplete="off"
            readOnly={true}
            disabled={!autoshutdown}
          />
          <button
            className="btn btn-outline-primary btn-rounded-end"
            style={!autoshutdown ? { color: "#999", borderColor: "#999", pointerEvents: "none", cursor: "not-allowed" } : {}}
            onClick={() => changeTime("plus")}
            disabled={!autoshutdown}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-plus"
              viewBox="0 0 16 16"
            >
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShutdownTimer;

type ShutdownTimerProps = {
  subscriptionId: string;
  vmStatus: string;
};
